<template>
  <div class="container" ref="container"></div>
</template>

<script setup>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { ref, onMounted } from "vue";
import * as dat from "dat.gui";
import gsap from "gsap";

let scene, scene2, camera, renderer, cube;
let ww = window.innerWidth;
let wh = window.innerHeight;
const container = ref(null); // 获取dom对象
const kitchenArr = {
  tip: "kitchen",
  names: ["2_l", "2_r", "2_u", "2_d", "2_b", "2_f"],
};
const kitchenOutArr = {
  tip: "kitchen",
  names: ["0_l", "0_r", "0_u", "0_d", "0_b", "0_f"],
};
const bedroomOutArr = {
  tip: "bedroom",
  names: ["20_l", "20_r", "20_u", "20_d", "20_b", "20_f"],
};
const bedroomArr = {
  tip: "bedroom",
  names: ["23_l", "23_r", "23_u", "23_d", "23_b", "23_f"],
};
// const childroomArr = {
//   tip: "childroom",
//   names: ["19_l", "19_r", "19_u", "19_d", "19_b", "19_f"],
// };
// const childroomOutArr = {
//   tip: "childroom",
//   names: ["11_l", "11_r", "11_u", "11_d", "11_b", "11_f"],
// };

const livingArr = {
  tip: "living",
  names: ["4_l", "4_r", "4_u", "4_d", "4_b", "4_f"],
};

// // 初始化场景
scene = new THREE.Scene();
function init() {
  // 初始化相机
  camera = new THREE.PerspectiveCamera(75, ww / wh, 0.1, 1000);
  // 设置相机位置
  camera.position.z = 0.1;
  // 初始化渲染器
  renderer = new THREE.WebGLRenderer();
  renderer.setSize(ww, wh);
  renderer.setPixelRatio(window.devicePixelRatio); // 保证渲染清晰度
}
init();

const render = () => {
  renderer.render(scene, camera);
  requestAnimationFrame(render);
};

function addListen() {
  // 监听画面变化
  window.addEventListener("resize", () => {
    const iwidth = window.innerWidth;
    const iheigt = window.innerHeight;
    //更新摄像头
    camera.aspect = iwidth / iheigt;
    // 更新摄像机投影矩阵
    camera.updateProjectionMatrix();
    // 更新渲染器
    renderer.setSize(iwidth, iheigt);
    // 设置渲染器像素比
    renderer.setPixelRatio(window.devicePixelRatio);
  });
}

// 添加立方体
const geometry = new THREE.BoxGeometry(10, 10, 10);

function addCube(arr) {
  const boxMaterials = [];
  arr.names.forEach((item, index) => {
    boxMaterials.push(loadTexture(`./imgs/${arr.tip}/${item}.jpg`, index));
  });
  cube = new THREE.Mesh(geometry, boxMaterials);
  scene.add(cube);
}
addCube(livingArr);
cube.geometry.scale(1, 1, -1);

// 负责把图片加工成材料
function loadTexture(url, index = 0) {
  let texture = new THREE.TextureLoader().load(url);
  let boxMaterial;
  if (index == "2" || index == "3") {
    // 如果是上和下图，则进行旋转
    texture.rotation = Math.PI;
    texture.center = new THREE.Vector2(0.5, 0.5);
    boxMaterial = new THREE.MeshBasicMaterial({
      map: texture,
    });
  } else {
    boxMaterial = new THREE.MeshBasicMaterial({
      map: texture,
    });
  }

  return boxMaterial;
}

const livingPoints = [
  {
    position: {
      x: 0.3,
      y: 0.02,
      z: -0.3,
    },
    detail: {
      title: "厨房",
    },
    name: "kitchen",
  },
  // {
  //   position: {
  //     x: -0.36,
  //     y: 0.04,
  //     z: -0.04,
  //   },
  //   detail: {
  //     title: "儿童房",
  //   },
  //   name: "childroom",
  // },
  {
    position: {
      x: -0.47,
      y: -0.04,
      z: -0.21,
    },
    detail: {
      title: "主卧",
    },
    name: "bedroom",
  },
  // {
  //   position: {
  //     x: -0.3,
  //     y: -0.25,
  //     z: -0.14,
  //   },
  //   name: "childroomOut",
  //   type: "circular",
  // },
  {
    position: {
      x: -0.79,
      y: -0.25,
      z: -0.36,
    },
    name: "bedroomOut",
    type: "circular",
  },
  {
    position: {
      x: 0.72,
      y: -0.25,
      z: -0.47,
    },
    name: "kitchenOut",
    type: "circular",
  },
];
const kitchenOutPoints = [
  {
    position: {
      x: -0.25,
      y: -0.25,
      z: -0.04,
    },
    name: "kitchen",
    type: "circular",
  },
  {
    position: {
      x: -0.25,
      y: -0.25,
      z: 0.72,
    },
    name: "living",
    type: "circular",
  },
];
const kitchenPoints = [
  {
    position: {
      x: 0.18,
      y: -0.25,
      z: 0.07,
    },
    name: "kitchenOut",
    type: "circular",
  },
];
const bedroomOutPoints = [
  {
    position: {
      x: -0.48,
      y: -0.25,
      z: -1.01,
    },
    name: "living",
    type: "circular",
  },
];
const bedroomPoints = [
  {
    position: {
      x: -0.5,
      y: -0.25,
      z: 0.07,
    },
    name: "bedroomOut",
    type: "circular",
  },
];
const poiObjects = [];
// 应用图形界面更改变量
// const gui = new dat.GUI();
function addTip(points) {
  for (var i = 0; i < points.length; i++) {
    let addrName = points[i].type ?? points[i].name;
    const pointTexture = new THREE.TextureLoader().load(
      `./imgs/tips/${addrName}.png`
    );
    const material = new THREE.SpriteMaterial({
      map: pointTexture,
    });
    var sprite = new THREE.Sprite(material);
    sprite.scale.set(0.08, 0.03, 0.1);
    sprite.position.set(
      points[i].position.x,
      points[i].position.y,
      points[i].position.z
    );
    sprite.name = points[i].name;
    poiObjects.push(sprite);
    // gui
    //   .add(sprite.position, "x")
    //   .min(-5)
    //   .max(5)
    //   .step(0.01)
    //   .name(`${points[i].name}x坐标`);
    // gui
    //   .add(sprite.position, "y")
    //   .min(-5)
    //   .max(5)
    //   .step(0.01)
    //   .name(`${points[i].name}y坐标`);
    // gui
    //   .add(sprite.position, "z")
    //   .min(-5)
    //   .max(5)
    //   .step(0.01)
    //   .name(`${points[i].name}z坐标`);

    scene.add(sprite);
  }
}

const addCubeList = {
  living() {
    addCube(livingArr);
    addTip(livingPoints);
  },
  kitchen() {
    addCube(kitchenArr);
    addTip(kitchenPoints);
  },
  kitchenOut() {
    addCube(kitchenOutArr);
    addTip(kitchenOutPoints);
  },
  bedroom() {
    addCube(bedroomArr);
    addTip(bedroomPoints);
  },
  bedroomOut() {
    addCube(bedroomOutArr);
    addTip(bedroomOutPoints);
  },
  // childroom() {
  //   addCube(childroomArr);
  // },
  // childroomOut() {
  //   addCube(childroomOutArr);
  // },
};

function addClick() {
  container.value.addEventListener("click", function (event) {
    // 阻止默认事件冒泡
    event.preventDefault();

    var raycaster = new THREE.Raycaster();
    var mouse = new THREE.Vector2();

    mouse.x = (event.clientX / document.body.clientWidth) * 2 - 1;
    mouse.y = -(event.clientY / document.body.clientHeight) * 2 + 1;
    // 通过摄像机和鼠标位置更新射线
    raycaster.setFromCamera(mouse, camera);
    // 计算物体和射线的焦点
    var intersects = raycaster.intersectObjects(poiObjects);
    if (intersects.length > 0) {
      // 清空场景的纹理
      scene.children = [];
      addCubeList[intersects[0].object.name]();
    }
  });
}

// 挂载完毕之后获取dom
onMounted(() => {
  // 添加控制器
  const controls = new OrbitControls(camera, container.value);
  controls.enableDamping = true; // 开启控制器阻尼
  container.value.appendChild(renderer.domElement);
  addClick();
  addTip(livingPoints);
  render(scene);
  addListen();
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.container {
  height: 100vh;
  width: 100vw;
  background-color: #f0f0f0;
}
</style>
